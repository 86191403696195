import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const StyledAboutSectionItemContainer = styled.div`
    width: 50%;
    height: 15.625rem;
    padding: 1.5rem 2.5rem;
    box-sizing: border-box;
    border: 0.0625rem solid rgba(255, 255, 255, 0.2);

    &:nth-child(odd) {
        border-right: none;
    }

    &:nth-child(n + 3) {
        border-top: none;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        width: 100%;
        height: 8rem;
        padding: 1.5rem;
        border-right: none;
        border-left: none;
        border-top: none;

        &:nth-child(1) {
            border-top: 0.0625rem solid rgba(255, 255, 255, 0.2);
        }
    }
`;

export const StyledTitle = styled.h3`
    font-family: "Nunito Sans", sans-serif;
    font-size: 4rem;
    line-height: 4.8125rem;
    letter-spacing: 0.01em;
    color: #c4b180;
    margin: 0;

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 2.5rem;
        line-height: 3rem;
        letter-spacing: 0.02em;
    }
`;

export const StyledDescription = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-weight: 200;
    font-size: 1.5rem;
    line-height: 2rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 0.5rem 0 0 0;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 1.25rem;
        line-height: 1.875rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1rem;
        line-height: 1.5rem;
        color: rgba(255, 255, 255, 0.6);
    }
`;
