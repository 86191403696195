import React from "react";
import {
    StyledAboutSectionItemContainer,
    StyledTitle,
    StyledDescription,
} from "./AboutSectionItem.style";

const AboutSectionItem = ({ aboutInfo: { title, description } }) => (
    <StyledAboutSectionItemContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
    </StyledAboutSectionItemContainer>
);

export default AboutSectionItem;
